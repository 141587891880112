// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-donacije-js": () => import("../src/pages/donacije.js" /* webpackChunkName: "component---src-pages-donacije-js" */),
  "component---src-pages-gallery-2019-js": () => import("../src/pages/gallery2019.js" /* webpackChunkName: "component---src-pages-gallery-2019-js" */),
  "component---src-pages-help-2019-js": () => import("../src/pages/help2019.js" /* webpackChunkName: "component---src-pages-help-2019-js" */),
  "component---src-pages-media-2019-js": () => import("../src/pages/media2019.js" /* webpackChunkName: "component---src-pages-media-2019-js" */),
  "component---src-pages-onama-js": () => import("../src/pages/onama.js" /* webpackChunkName: "component---src-pages-onama-js" */),
  "component---src-pages-standings-2019-js": () => import("../src/pages/standings2019.js" /* webpackChunkName: "component---src-pages-standings-2019-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-turnir-js": () => import("../src/pages/turnir.js" /* webpackChunkName: "component---src-pages-turnir-js" */)
}

